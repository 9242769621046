import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueMeta from 'vue-meta'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

[BootstrapVue, BootstrapVueIcons, VueMeta].forEach((x) => Vue.use(x));

new Vue({
  render: h => h(App),
}).$mount('#app')
