lsina<template>
    <div id="slider" class="slider">
        <div class="slide-item overlay-dark">
            <div class="container">
                <div class="box"><!-- flex item -->
                    <img src="../assets/images/logo.png">
                    <h2 class="mt-5">È ora una residenza privata.</h2>
                    <h2>Is now a private residence.</h2>
                    <hr>
                    <a href="mailto:info@casafelsina.com">info@casafelsina.com</a>
                    <hr>
                    <div class="row logo-carousel">
                        <div class="col-12">
                            <VueSlickCarousel v-bind="settings">
                              <div><a href="https://www.positanonotizie.it" target="_blank"><img src="../assets/images/positanonotizie.png"></a></div>
                                <div><a href="https://www.amalficoast.it" target="_blank"><img src="../assets/images/amalficoast.png"></a></div>

                                <div><a href="https://www.amaiorinews.it" target="_blank"><img src="../assets/images/amaiorinews.png"></a></div>
                                <div><a href="https://www.amalfinews.it" target="_blank"><img src="../assets/images/amalfinews.png"></a></div>
                                <div><a href="https://www.ravellonotizie.it" target="_blank"><img src="../assets/images/ravellonotizie.png"></a></div>
                            </VueSlickCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: "Slider.vue",
        components: {VueSlickCarousel},
        data() {
            return {
                settings: {}
            }
        },
        mounted() {
            this.settings = {
                "dots": true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 2,
                "slidesToScroll": 1,
                "autoplay":true,
            }
        }
    }
</script>

<style scoped lang="scss">
    .slide-item {
        background-image: url("../assets/images/background.jpg");
        position: relative;
        background-size: cover;
        background-position: center;
        height: 100vh;


        .container {
            display: flex; /* establish flex container */
            flex-direction: column; /* make main-axis vertical */
            justify-content: center; /* align items vertically, in this case */
            align-items: center; /* align items horizontally, in this case */
            z-index: 999;
            position: relative;
            height: 100vh;

            .box {
                width: 350px;
                text-align: center;
                background-color: white;
                padding: 50px 30px;
                -webkit-box-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
                -moz-box-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
                box-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
                border-radius: 30px;

                h2 {
                    color: darkgrey;
                    font-size: 20px;
                }

                .logo-carousel {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .overlay-dark {
        &:before {
            content: "";
            background-color: #222222;
            opacity: 0.5;
            display: inline-block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }
    }
</style>
