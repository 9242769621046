<template>
  <div id="app">
   <Slider />
  </div>
</template>

<script>

import Slider from './components/Slider.vue'

export default {
  name: 'App',
  components: {
    Slider,
  },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Casa F'
        // all titles will be injected into this template
    }
}
</script>

<style>


</style>
